<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">修改密碼</li>
        </ol>
        </nav>
        <hr class="w-100">
        <Form class="w-100" @submit="modify_password()" :validation-schema="schema">
            <div class="col-12 row text-center mx-auto">
                <h1 class="h3 mb-3 fw-normal">修改密碼</h1>
            </div>
            <div class="col-12 row justify-content-center mx-auto modify-password-form">
                <div class="col-12 mb-3">
                    <div class="input-group">
                        <div class="form-floating">
                            <Field name="current_password" v-slot="{ meta, field }" v-model="password_form.current_password">
                                <input
                                    id="current_password"
                                    v-bind="field"
                                    class="form-control"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    :type="current_pwd_type"
                                    placeholder="原密碼"
                                    autocomplete="on"
                                />
                            </Field>
                            <label for="current_password">原密碼</label>
                        </div>
                        <button type="button" class="input-group-text btn-dark btn" v-on:click="show_current_pwd()">
                            <i class="bi bi-eye-fill"></i>
                        </button>
                        <ErrorMessage name="current_password" class="form-floating-invalid-feedback"/> 
                    </div>
                    <div id="current_password_help" class="form-text">請輸入原密碼</div>
                </div>
            </div>
            <div class="col-12 row justify-content-center mx-auto modify-password-form">
                <div class="col-12 mb-3">
                    <div class="input-group">
                        <div class="form-floating">
                            <Field name="password" v-slot="{ meta, field }" v-model="password_form.password">
                                <input
                                    id="password"
                                    v-bind="field"
                                    class="form-control"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    :type="pwd_type"
                                    placeholder="修改密碼"
                                    autocomplete="on"
                                />
                            </Field>
                            <label for="password">修改密碼</label>
                        </div>
                        <button type="button" class="input-group-text btn-dark btn" v-on:click="show_pwd()">
                            <i class="bi bi-eye-fill"></i>
                        </button>
                        <ErrorMessage name="password" class="form-floating-invalid-feedback"/> 
                    </div>
                    <div id="password_help" class="form-text">8-20碼，包含大寫、小寫英文及數字</div>
                </div>
            </div>
            <div class="col-12 row justify-content-center mx-auto modify-password-form">
                <div class="col-12 mb-3">
                    <div class="input-group">
                        <div class="form-floating">
                            <Field name="password_confirmation" v-slot="{ meta, field }" v-model="password_form.password_confirmation">
                                <input
                                    id="password_confirmation"
                                    v-bind="field"
                                    class="form-control"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    :type="pwd_confirm_type"
                                    placeholder="確認修改密碼"
                                    autocomplete="on"
                                />
                            </Field>
                            <label for="password_confirmation">確認修改密碼</label>
                        </div>
                        <button type="button" class="input-group-text btn-dark btn" v-on:click="show_pwd_confirm()">
                            <i class="bi bi-eye-fill"></i>
                        </button>
                    </div>
                    <ErrorMessage name="password_confirmation" class="form-floating-invalid-feedback"/> 
                    <div id="password_confirmation_help" class="form-text">請輸入與修改密碼相同</div>
                </div>
            </div>
            <div class="col-12 row text-center mx-auto">
                <div class="mb-3">
                    <button class="m-2 btn btn-primary">送出</button>
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
import { modify_password } from '../../api/auth';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { modify_password_rules } from '../../rules/user/modify_password';
import { mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
    components: {
        Field,
        Form,
        ErrorMessage
    },
    data() {
        return {
            password_form: {
                current_password: "",
                password: "",
                password_confirmation: "",
            },
            current_pwd_type: "password",
            pwd_type: "password",
            pwd_confirm_type: "password",
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        modify_password() {
            modify_password(this.password_form).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary'
                        },
                        buttonsStyling: false
                    })

                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: '密碼修改成功！',
                        icon: 'success',
                        confirmButtonText: '確認',
                        allowOutsideClick: false,
                    });
                }
            }, error => {
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: error.response.data.message,
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                });
            });
        },
        show_current_pwd() {
            if (this.current_pwd_type === 'password') {
                this.current_pwd_type = 'text';
            } else {
                this.current_pwd_type = 'password';
            }
        },
        show_pwd() {
            if (this.pwd_type === 'password') {
                this.pwd_type = 'text';
            } else {
                this.pwd_type = 'password';
            }
        },
        show_pwd_confirm() {
            if (this.pwd_confirm_type === 'password') {
                this.pwd_confirm_type = 'text';
            } else {
                this.pwd_confirm_type = 'password';
            }
        }
    },
    computed: {
        schema() {
            return modify_password_rules;
        },
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
    }
};
</script>
